import React from 'react'
import TopWelcomeSection from '../../../components/home/TopWelcomeSection'
import SwiperSlider from '../../../components/home/Swiper'
import Varities from '../../../components/home/varities/Varities'
import VarietyCard from '../../../components/home/varities/components/VarietyCard'
import ExportCounter from '../../../components/home/counter/ExportCounter'
import OwnerHighlights from '../../../components/home/ownerProfile/OwnerProfile'
import ChooseUsBanner from '../../../components/home/chooseUsBanner/ChooseUsBanner'

const Home = () => {
  return (
    <div>
      <TopWelcomeSection />    
      <SwiperSlider />
      <ExportCounter />
      <ChooseUsBanner />
      <OwnerHighlights />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Home