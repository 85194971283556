import React from 'react';
import "./ChooseUsBanner.css"
import natureImg from "../../../assets/home/img_natural.jpg";
import worldwideImg from "../../../assets/home/img_worldwide_trade.jpg";
import freshQualityImg from "../../../assets/home/img_fresh_quality.png";
import certifiedImg from "../../../assets/home/certified-medal-icon-png.webp";
import certifiedImg2 from "../../../assets/home/img_certified.png";
import whyChooseBgimg from "../../../assets/home/why-choose-bg.jpg";

const contentList = [
    {text: "Globally Certified & Trusted", img: certifiedImg},
    {text: "Worldwide exports", img: worldwideImg},
    {text: "Natural & Organic", img: natureImg},
    {text: "Fresh & Quality", img: freshQualityImg},
]

const ChooseUsBanner: React.FunctionComponent = () => {
    return (
        <div id="why-choose">
            <h2>Why We're Your Best Choice</h2>
            <div className="content">
            {contentList.map((ele, i: number) => <div key={i}>
                <img src={ele.img} />
                <h5>{ele.text}</h5>
            </div>
            )}
            </div>
        </div>
    )
}

export default ChooseUsBanner