import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { CLIENT_NUMBER } from '../../../contants/common';

const contactNumber = CLIENT_NUMBER;
const WhatsappContact = () => {
  const [isHovered, setIsHovered] = useState(false);  // State to handle hover effect

  const hoveredStyle = isHovered ? { "backgroundColor": "#25D366" } : {}

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "#ffffff",
      border: '2px solid #25D366',  // WhatsApp green color
      padding: '0.66rem 0.95rem',
      borderRadius: '1.25rem',
      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    icon: {
      color: '#25D366',
      fontSize: '1.5rem',
      marginRight: '0.66rem',
    },
    contactNumber: {
      color: 'green',
      fontWeight: 'bold',
    }
  };

  if (isHovered) {
    styles.container.backgroundColor = "#25D366";
    styles.icon.color = "#ffffff";
    styles.contactNumber.color = "#ffffff";
  }

  return (
    <>
      <a
        href={`https://wa.me/${contactNumber.replace(/[^\d]/g, '')}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <div
          style={{ ...styles.container, ...hoveredStyle }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <FaWhatsapp style={styles.icon} />
          <span style={styles.contactNumber}>{contactNumber}</span>

        </div>
      </a>
    </>
  );
};

export default WhatsappContact;
