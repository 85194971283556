import React from 'react';
import ownerPic from "../../../assets/common/owner_pic.jpeg";
import "./OwnerProfile.css";

const OwnerHighlights = () => {
    return (
        <div id="home-owner-highlights">
            <div className="text">
                <p>
                    We specialize in exporting premium agricultural products, fruits, spices, rice, and clothing to markets around the globe. Our commitment to quality and sustainability drives us to work closely with local farmers and suppliers, ensuring that only the freshest and most authentic products reach our customers. From exotic spices to premium textiles, we take pride in offering a diverse range of goods that meet the highest standards.
                </p>
                <br/>
                <br/>
                <p>
                    With a focus on customer satisfaction, we strive to provide a seamless and reliable experience. Our dedication to timely delivery, competitive pricing, and exceptional service sets us apart in the global marketplace. Thank you for choosing <b>Aanabia Gulf & Trading Services</b> as your trusted partner in quality exports.
                </p>
            </div>
            <div>
                <img src={ownerPic}/>
            </div>
        </div>
    )
}

export default OwnerHighlights