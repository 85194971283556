//@ts-nocheck
import React, { useRef, useState } from 'react';
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './Swiper.css';
import VarietyCard from './varities/components/VarietyCard';
import sliderImgFruits from "../../assets/home/home_slider_fruits_comp.jpg";
import sliderImgCereals from "../../assets/home/home_slider_cereals_comp.jpg";
import sliderImgSpices from "../../assets/home/home_slider_spices_com.jpg";
import sliderImgVegs from "../../assets/home/home_slider_vegs_comp.jpg";
import sliderImgClothes from "../../assets/home/home_slider_garments_all_compressed.jpg";

const cards = [
    { imgLink: sliderImgFruits, texts: ["Fresh Fruits", "Contact for Rates", "1000Kg"] },
    { imgLink: sliderImgSpices,  texts: ["Spices & Herbs", "Contact for Rates", "1000Kg"] },
    { imgLink: sliderImgVegs,  texts: ["Fresh Vegetables", "Contact for Rates", "1000Kg"] },
    { imgLink: sliderImgClothes,  texts: ["Trendy Clothes", "Contact for Rates", "100 items"] },
    { imgLink: sliderImgCereals, texts: ["Whole Grains", "Contact for Rates", "1000Kg"] },
]
export default function App() {
    const [swiperRef, setSwiperRef] = useState(null);
    const appendNumber = useRef(cards?.length);
    const prependNumber = useRef(1);
    const [slides, setSlides] = useState(
        // Array.from({ length: 21 }).map((_, index) => <VarietyCard />)
        cards.map((_, index) => <VarietyCard ele={_} />)
    );

    return (
        <>
            <Swiper
                modules={[Virtual, Navigation, Pagination]}
                onSwiper={setSwiperRef}
                // slidesPerView={4}
                // centeredSlides={true}    //At initial: start slide from center keep left part empty
                spaceBetween={10}
                pagination={{
                    type: 'fraction',
                }}   //show slide number below focused card
                navigation={true}
                // virtual  //this was loading 2blank ele initially due to opimisation of large data set
                breakpoints={{
                    // When window width is >= 640px, show 2 slides
                    320: {
                        slidesPerView: 2,
                    },
                    // When window width is >= 768px, show 3 slides
                    768: {
                        slidesPerView: 3,
                    },
                    // When window width is >= 1024px, show 4 slides
                    1024: {
                        slidesPerView: 4,
                    },
                }}
            >
                {slides.map((slideContent, index) => (
                    <SwiperSlide key={slideContent} virtualIndex={index}>
                        {/* <VarietyCard /> */}
                        {slideContent}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
