import React from 'react'
import OwnerHighlights from '../../../components/home/ownerProfile/OwnerProfile'
import ExportCounter from '../../../components/home/counter/ExportCounter'

const AboutUs = () => {
    return (
        <div>
            <OwnerHighlights />
            <ExportCounter />
        </div>
    )
}

export default AboutUs