import React, { useState } from 'react';
import { CLIENT_EMAIL } from '../../../contants/common';
import { MdEmail } from 'react-icons/md';

const EmailContact = () => {
  const [isHovered, setIsHovered] = useState(false);  // State to handle hover effect

  const hoveredStyle = isHovered ? { "backgroundColor": "orange" } : {}

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: "#ffffff",
      border: '2px solid orange',  // WhatsApp green color
      padding: '0.66rem 0.95rem',
      borderRadius: '1.25rem',
      boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    icon: {
      color: 'orange',
      fontSize: '1.5rem',
      marginRight: '0.66rem',
    },
    contactNumber: {
      color: 'green',
      fontWeight: 'bold',
    }
  };

  if (isHovered) {
    styles.container.backgroundColor = "orange";
    styles.icon.color = "#ffffff";
    styles.contactNumber.color = "#ffffff";
  }

  return (
    <div
      style={{ ...styles.container, ...hoveredStyle }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <MdEmail style={styles.icon} />
      <span style={styles.contactNumber}>{CLIENT_EMAIL}</span>
    </div>
  );
};

export default EmailContact;
