import React from 'react';
import "./ExportCounter.css";

const ExportCounter = () => {
    return (
        <div id="home-export-counter">
            <div className="content 1">
                <h2>75</h2>
                <p>Products</p>
            </div>
            <div className="content 2">
                <h2>05</h2>
                <p>Clients Worldwide</p>
            </div>
            <div className="content 3">
                <h2>12</h2>
                <p>Employees</p>
            </div>
            <div className="content 4">
                <h2>05</h2>
                <p>Years of Experience</p>
            </div>
            <div className="content 5">
                <h2>20+</h2>
                <p>Customers</p>
            </div>
        </div>
    )
}

export default ExportCounter;