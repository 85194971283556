import React from 'react';
import { SlLocationPin } from "react-icons/sl";
import dummyImg from "../../../../assets/home/mix_fruits.jpg";
import "./VarietyCard.css"




const tempData = {
  _id: "63be8383cfbc762c6474e5ef",
  userRef: "63aebd7cbec54944187f0ca7",
  propertyRef: {
    imageLinks: [dummyImg],
    _id: "63bbd6dea7c0d21d34698e49",
    location: "Paseo de Roxas, Makati",
    name: "ONE ROXAS TRIANGLE TOWER",
  },
  metaDataRef: {
    salePriceRange: {
      min: 70000000,
      max: 240000000,
    },
    rentPriceRange: {
      min: 150000,
      max: 600000,
    },
  },
  updatedAt: "2023-01-11T09:38:11.490Z",
};

function nFormatter(num: any, decimal = 1) {
  if (!num || !Number(num)) {
    return "N.A.";
  }
  const terms = [
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
  ];

  const relTerm = terms.find((ele) => num >= ele.value);
  if (relTerm) {
    num = (num / relTerm.value).toFixed(decimal);
    return Math.floor(num) == num
      ? Math.floor(num) + relTerm.symbol
      : num + relTerm.symbol;
  } else {
    return num;
  }
}
const VarietyCard = ({ ele }: any) => {
  const {
    imgLink = dummyImg,
    texts = []
  }: any = ele || {};

  return (
    <div id="variety-card">
      <div className="cardFeatureDiv">
        <img src={imgLink} alt="image" />
        <div className="featuredDiv">
          <div className="recent-items-h3">
            <h3> {texts[0] || "N/A -0"} </h3>
          </div>
          <p>
            Unit Sale Price Range{" "}
            <b>{texts[1] || ("SAR " + 20 + "-" + 30)}</b>
          </p>
          <p>
            <b>
              <SlLocationPin /> Minimize. order {texts[2] || ("1000Kg")}
            </b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default VarietyCard