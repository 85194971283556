import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from './pages/Unprotected/Home/Home';
import Header from './components/common/header/Header';
import Product from './pages/Unprotected/Product/Product';
import Footer from './components/common/footer/Footer';
import AboutUs from './pages/Unprotected/aboutUs/AboutUs';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="product" element={<Product />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
