import React, { useEffect, useState } from "react";
import "./TopWelcomeSection.css";
// import bannerImg from "../../assets/home/mix_fruits.jpg";
// import bannerImg from "../../assets/home/agriculture_banner_compressed.jpg";
// import bannerImg from "../../assets/home/banner_3.jpg";
import bannerImg from "../../assets/home/banner_4.jpg";
// import builderShangri from "../../assets/homepage/developer-island-shangri-la-shangri-la-hotels.png";
// import builderAyalaland from "../../assets/homepage/developer-ayala-land-premier.png";
// import builderRobinsons from "../../assets/homepage/developer-robinsons-place.png";
// import builderRockwell from "../../assets/homepage/developer-rockwell-land.png";
// import builderfilinvest from "../../assets/homepage/developer-filinvest.png";
// import builderSMDC from "../../assets/homepage/developer-smdc.png";
import { useNavigate } from "react-router-dom";
// import designImg from "../../assets/homepage/Ellipse_One.png";
// import vectorImg from "../../assets/homepage/BACKGROUND1.png";


// const bannerImg = "https://www.yuvarajuagroimpex.com/uploads/banner/1707201520923.jpg"
// const builders = [builderRockwell, builderShangri, builderAyalaland, builderfilinvest, builderRobinsons, builderSMDC];
const varities = [
    "Fresh Fruits and Vegetables",
    "Whole Grains and Legumes",
    "Aromatic Spices and Herbs",
    "Pure Edible Oils",
    "Premium Nuts and Seeds",
]

const TopWelcomeSection = () => {
    const [error, setError] = useState("");
    //   const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleGenerateReport = async (formData: any, reset: any, handleClose: any, setFormError: any) => {
        // dispatch(loaderAction(true));
        // HttpClient.post("/user-enquiry/report/request", formData)
        //   .then((data) => {
        //     dispatch(loaderAction(false));
        //     reset();
        //     handleClose();
        //     toast.success("request submitted successfully");
        //     if (formData.property === "Sample") {
        //       navigate(`/sample/report`)
        //     }
        //   }).catch((err) => {
        //     dispatch(loaderAction(false));
        //     const errorMessage: string = err?.response?.data?.message || err?.message || "request submission failed, try again";
        //     setFormError(errorMessage);
        //     toast.error(errorMessage);
        //   })
    }



    return (
        <div id="home-search">
            <div className="flex-row-2-full">
                <div className="flex-column-2-half">
                    <h3>- Connecting You with the Best of Nature: <b>Aanabia Gulf & Trading Services</b></h3>
                    <h1 className="mainHeadingHome">
                        Get fresh Fruits, Vegetables, Whole Grains,
                        Spices & Herbs directly sourced from reliable,
                        certified farms.
                    </h1>
                    <p className="homeSecondaryText">
                        At Aanabia's Exports, we pride ourselves on delivering the highest quality agricultural products from the earth's bounty to your doorstep. Our dedication to sustainability, integrity, and superior service ensures that we meet the diverse needs of our global customers.
                    </p>
                </div>
                <img src={bannerImg} />
            </div>


            <div className="builders">
                <h1> Variety You Can Count On </h1>
                {/* <div>
                    {
                        varities.map((ele: any, i: number) => <li> <h3>{ele} </h3></li>)
                    }
                </div> */}
            </div>

        </div>
    );
};

export default TopWelcomeSection;
