import "./Header.css";
import { useState, useEffect } from "react";
import logoImage from "../../../assets/common/logo.jpeg";

import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import WhatsappContact from "../whatsappContact/WhatsappContact";
import EmailContact from "../emailContact/EmailContact";

const Header = () => {
  const menu = [
    { name: "Home", to: "/" },
    // { name: "Featured", to: "#featured-buildings" },     //temporarily commented, it will used again in future
    { name: "About Us", to: "/about" },
    { name: "Contact", to: "/contact" },
  ];

  const [clicked, setClicked] = useState({ key: -1, name: "active-page" });


  const handleButtonClick = (key: number) => {
    setClicked({
      ...clicked,
      key: key,
    });
  };
  useEffect(() => {
    const currentPage = window.location.pathname;
    let page = menu.findIndex(
      (ele) => ele.to.slice(1) && currentPage.includes(ele.to.slice(1))
    );
    if (page === -1) {
      page = currentPage === "/" ? 0 : -1;
    }
    setClicked({
      ...clicked,
      key: page,
    });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="parentHeadOuterDiv">
      <div className="header">
        <div className="header-logo">
          <Link to="/">
            <img src={logoImage} alt="logo" />
          </Link>
        </div>

        <div className="header-buttons header-menu">
          {menu.map((item, key) => {
            if (item.name == "Featured") {
              return (
                <a href="#featured-buildings" key={key}>
                  <button> Featured </button>
                </a>
              );
            }
            return (
              <Link
                to={item.to}
                style={
                  clicked.key === key
                    ? {
                      textDecoration: "none",
                      borderBottom: "2px solid blue",
                    }
                    : {
                      textDecoration: "none",
                      background: "none",
                    }
                }
                key={key}
                className={clicked.key === key ? "active-nav" : "inactive-nav"}
              >
                <button
                  name={item.name}
                  onClick={() => handleButtonClick(key)}
                  className={clicked.key === key ? clicked.name : "inactive"}
                >
                  {" "}
                  {item.name}{" "}
                </button>
              </Link>
            );
          })}
        </div>

        <div className="header-buttons-user">
          <WhatsappContact />
          <EmailContact />
        </div>

        <div className="header-hamburgermenu">
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MenuIcon sx={{ width: "2.7rem", height: "2.7rem", color: "black" }}></MenuIcon>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                width: "60%",
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >

            {menu.map((item, key) => {
              if (item.name == "Featured") {
                return (
                  <a href="#featured-buildings" key={key}>
                    <button> Featured </button>
                  </a>
                );
              }
              return (
                <MenuItem onClick={handleClose} key={key}>
                  <Link
                    to={item.to}
                    style={
                      clicked.key === key
                        ? {
                          textDecoration: "none",
                          borderBottom: "2px solid blue",
                        }
                        : {
                          background: "none"
                        }
                    }
                    key={key}
                    className={clicked.key === key ? "active-nav" : "inactive-nav"}
                  >
                    <p
                      onClick={() => handleButtonClick(key)}
                      className={clicked.key === key ? clicked.name : "inactive"}
                    >
                      {" "}
                      {item.name}{" "}
                    </p>
                  </Link>
                </MenuItem>
              );
            })}

            <Divider />
            <div>
              <WhatsappContact />
            </div>
          </Menu>
        </div>

      </div>
    </div>
  );
};

export default Header;