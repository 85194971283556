import React from "react";
import "./Footer.css";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdOutlineLanguage } from "react-icons/md";
import fullLogo from "../../../assets/common/logo.jpeg";
import { CLIENT_EMAIL2, CLIENT_NUMBER, CLIENT_NUMBER2 } from "../../../contants/common";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-main">
        <div className="first-column">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img className="logoImgFooter" src={fullLogo} alt="logo loading" />
          </Link>
          <div className="socialMediaDiv">
            <a href="#">
              <FiFacebook size={"1.2rem"} fill={"#F7F7EE"} />
            </a>
            <a href="#">
              <FiTwitter size={"1.2rem"} fill={"#F7F7EE"} />
            </a>
            <a href="#">
              <FiLinkedin size={"1.2rem"} fill={"#F7F7EE"} />
            </a>
            <a href="#">
              <FiInstagram size={"1.2rem"} />
            </a>

          </div>
        </div>

        <ul className="second-column">
          <li className="footerCon"> Contact </li>
          <li> Email <span> {CLIENT_EMAIL2}  </span></li>
          <li> phone <span> {CLIENT_NUMBER} </span></li>
          <li> phone <span> {CLIENT_NUMBER2} </span></li>
        </ul>

        <ul className="third-column">
          <li className="footerCon"><Link to="/">
            Special Links
          </Link></li>
          <li><Link to="/" onClick={() => window.scrollTo(0, 0)}>
            Home
          </Link></li>
          <li><Link to="/about" onClick={() => window.scrollTo(0, 0)}>
            About Us
          </Link></li>
          <li><Link to="/contact" onClick={() => window.scrollTo(0, 0)}>
            Contact
          </Link></li>
        </ul>
      </div>

      <ul className="footer-bottom">
        <li> <MdOutlineLanguage /> &nbsp;English </li>
        <li> ©2024  Aanabia Exports </li>
      </ul>
    </div>
  );
};

export default Footer;


